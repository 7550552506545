
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CustomerService } from '@/services/customer-service';
import { AnonCustomerContactModel } from '@/store/models/RequestModels';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class AnonContactForm extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };
  /* Properties */
  @Prop() counterColor!: string;
  @Prop() shouldHideSubmitButton!: boolean;
  /* Store Actions */
  /* Watchers */
  @Watch('isFormValid')
  onIsFormValidChanged(val: boolean) {
    this.$emit('formChanged', val);
  }
  /* Data */
  model: AnonCustomerContactModel = new AnonCustomerContactModel();
  numberOfTimesSubmitted: number = 0;
  isFormValid: boolean = false;
  isSendingEmail: boolean = false;
  /* Computed */
  sendingEmail() {
    return this.isSendingEmail;
  }
  /* Async Functions */
  async SendEmail() {
    try {
      this.model.Url = window.location.href;
      this.model.BrowserType = window.navigator.userAgent;
      await CustomerService.SendAnonCustomerContactEmail(this.model);
      this.$refs.form.reset();
      this.$emit('emailSent');
      SetSnackBar('Sent Email Successfully. We will get back to you shortly!');
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isSendingEmail = false;
  }
  /* Utility Functions */
  onSubmit() {
    this.isSendingEmail = true;
    setTimeout(() => {
      this.SendEmail();
    }, this.numberOfTimesSubmitted * 2);
  }
  isValid() {
    return this.isFormValid;
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    if (!this.counterColor) return;
    let counterElements = document.querySelectorAll('.form-group .v-input .v-counter');
    for (let element of counterElements) {
      (element as HTMLElement).style.color = this.counterColor;
    }
  }
  /* Created */
  /* Emmited Functions */
}
