import { render, staticRenderFns } from "./PostHeader.vue?vue&type=template&id=4f638945&scoped=true"
import script from "./PostHeader.vue?vue&type=script&lang=ts"
export * from "./PostHeader.vue?vue&type=script&lang=ts"
import style0 from "./PostHeader.vue?vue&type=style&index=0&id=4f638945&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f638945",
  null
  
)

export default component.exports